import * as constans from './constans';
import { createHashHistory } from 'history';
import axios from 'axios';
import {message} from 'antd';
import {url} from '../../../axiosUrl';
const reset = (values)=>({
    type:constans.RESET,
    data:values
})
export const resetLoadingTrue = ()=>({
    type:constans.RESET_LOADINGTRUE
})
const resetLoadingFalse = ()=>({
    type:constans.RESET_LOADINGFALSE
})
export const resetPassword = (values)=>{
    return (dispatch)=>{
        axios.post(url+'UpdataPassword1?phoneNum='+
        values.userPhone+
        '&passWord='
        +values.password
        +'&Code='+
        values.phoneCode)
        .then(res=>{
            dispatch(resetLoadingFalse())
            if(res.data.code === 200){
                dispatch(reset(res.data));
				message.success("重置密码成功，正在自动跳转登录页面");
				setTimeout(()=>{createHashHistory().push('/login?entrance=resetPassword')}, 1000);
				// return (
				//     <Success title="密码重置成功" to='/login' btn='去登录'/>
				// )
            }else{
                
            }
                      
        }).catch(res=>{
            
        })
    }
    
}