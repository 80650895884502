import * as constans from './constans';
import { fromJS } from 'immutable';
const defalueState = fromJS({
    apiName:"",
    apiDesc:'',
    apiUrl:'',
    apiParam:[],
    apiFrequency:'',
    apiResult:"",
    apiParamDesc:[],
    nav:[],
    getCode:[],
    codeC:[],
    zidingyi:"",
	type:''
});
export default (state = defalueState,action)=>{
    if(action.type === constans.GETAPIR){
        return state.merge({
            'apiName':action.data.apiName,
            'apiDesc':action.data.apiDesc,
            'apiUrl':action.data.apiUrl,
            'apiParam':action.data.apiParam,
            'apiFrequency':action.data.apiFrequency,
            'apiResult':action.data.apiResult,
            'apiParamDesc':action.data.apiParamDesc,
            'zidingyi':null,
            'codeC':null,
			'type':action.types
        })
    }
    if(action.type === constans.GETAPINAVR){
        return state.merge({
            'nav':action.data
        })
    }
    if(action.type === constans.GETCODES){
        return state.merge({
            'getCode':action.data
        })
    }
    if(action.type === constans.GETAPIC){
        return state.merge({
            'codeC':action.data,
            'zidingyi':null,
			'type':action.types
        })
    }if(action.type === constans.GETAPIC2){
        console.log(action.data);
        return state.merge({
            'zidingyi':action.data,
            'codeC':null
        })
    }
    return state
}