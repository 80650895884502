import * as constans from './constans'
import axios from 'axios';
import { fromJS} from 'immutable';
import {url} from '../../../../axiosUrl';
import { createHashHistory } from 'history';
const orders = (data)=>({
    type:constans.ORDER,
    data:fromJS(data)
})
// 获取订单详情
export const order = (orderId)=>{
    return(dispatch)=>{
        axios.get(url+'order/listByOrderId?orderId='+orderId).then((res)=>{
            if(res.data.code === 200){ 
                var value = res.data.data;
                dispatch(orders(value));
            }else{
            } 
        }).catch((res)=>{
            console.log("error");
        })
    }
}
const AlipayLoadingFalse= ()=>({
    type:constans.ALIPAY_LOADINGFALSE
})
export const AlipayLoadingTrue= ()=>({
    type:constans.ALIPAY_LOADINGTRUE
})
// 支付宝支付请求
export const alipay = (orderId)=>{
    return (dispatch)=>{
		axios.post(url+'pay/ali/creatOrder/?outTradeNo='+orderId, {
		  headers: {'Content-type':"charset=utf-8"}//设置header信息
		}).then((res) => {
		    dispatch(AlipayLoadingFalse());
		    if(res.data.code === 500){
		        
		    }else{
		        if(res.data.code === 200){
		            createHashHistory().push('/payCompleted');
		        }else{
					var newwindow = window;
					var datas = res.data;
					datas = datas.replace("form","form accept-charset='UTF-8' onsubmit='document.charset='UTF-8'")
		            newwindow.document.write('<html><title>支付宝支付</title>');
		            newwindow.document.write('<body>');
		            newwindow.document.write(datas);
		            newwindow.document.write('</body></html>');
		        }
		    }
		})
    }
}
export const WXpayLoadingTrue = ()=>({
    type:constans.WXPAY_LOADINGTRUE
})
const WXpayLoadingFalse = ()=>({
    type:constans.WXPAY_LOADING
})
const wxpaycode = (data)=>({
    type:constans.WXPAYCODE,
    data
})
// 微信支付请求
export const WXpay = (orderId)=>{
    return (dispatch)=>{
        axios.post(url+'pay/wx/creatOrder?outTradeNo='+orderId).then((res)=>{
            dispatch(WXpayLoadingFalse());
            if(res.data.code === 500){
               
            }else{
               if(res.data.code === 200){
                    if(res.data.message === "免费或试用服务创建成功"){
                        
                        createHashHistory().push('/payCompleted');
                    }else{
                        dispatch(wxpaycode(res.data))
                    } 
                }
            }
        })
    }
}
export const closeModal = ()=>({
    type:constans.CLOSEMODAL
})
// 其他支付方式请求
export const otherPay=(data)=>({
    type:constans.OTHERPAY,
    data:data
})
export const showOtherPay = (orderId)=>{
    return (dispatch)=>{
        axios.get(url+'order/getOrderBank?orderId='+orderId).then((res)=>{
            if(res.data.code === 500){
               
            }else{
                if(res.data.code === 200){
                    if(res.data.message === "免费或试用服务创建成功"){
                        
                        createHashHistory().push('/payCompleted');
                    }else{
                        dispatch(otherPay(res.data))
                    } 
                }
            }
        })
    }
}
