import * as constans from './constans'
import axios from 'axios';
import { message} from 'antd';
import {url} from '../../../../axiosUrl';
import { createHashHistory } from 'history';
const city = (data)=>({
    type:constans.CITY,
    data:data
})
export const getCity = ()=>{
    return(dispatch)=>{
        axios.get('api/station.json').then((res)=>{
            dispatch(city(res.data))
        })
    }
}
export const modifyDataLoadingTrue = ()=>({
    type:constans.MODIFY_DATALOADINGTRUE
})
const modifyDataLoadingFalse = ()=>({
    type:constans.MODIFY_DATALOADINGFALSE
})
export const improveInfor = (values,userCity,history)=>{
    const user = JSON.parse(window.localStorage.getItem('user'));
    if(user === null){
        return ()=>{
            createHashHistory().push('/login')
        }
    }else{
        return (dispatch)=>{
            axios.post(
                url+'UpdataUserInfo?userName='+encodeURI(values.name,"UTF-8")
                +'&company='+encodeURI(values.company,"UTF-8")
                +'&position='
                +encodeURI(values.position,"UTF-8")
                +'&userSex='
                +encodeURI(values.sex,"UTF-8")
                +'&userAddressCity='+encodeURI(userCity,"UTF-8")
                +'&userId='+user.userId).then((res)=>{
                    dispatch(modifyDataLoadingFalse())
                    if(res.data.code === 200){
                        message.success("修改用户信息成功");
                        window.localStorage.setItem('newUserName', values.name);
                        createHashHistory().push('/personalCenter/center');
                        // setTimeout(dispatch(changeInfo()),2000);
                    }else{
                       
                    }
                })
        }
    }
    
    
}
const userinfo = (data)=>({
    type:constans.USERINFO,
    data:data
})
export const getuserinfo = (history)=>{
    const user = JSON.parse(window.localStorage.getItem('user'));
    if(user === null){
        return ()=>{
            createHashHistory().push('/login')
        }
    }else{
        return (dispatch)=>{
            axios.get(url+'QueryUserInfo?userKey='+user.userKey).then((res)=>{
                if(res.data.code === 200){
                    dispatch(userinfo(res.data.data))
                }else{
                    
                }
            })
        }
    }
    
}
