import * as constans from './constans'
import axios from 'axios';
import { message} from 'antd';
import {url} from '../../../../axiosUrl';
import returnTop from '../../../../returnTop';
const List = (data,page)=>({
    type:constans.LIST,
    data:data,
    page:page
})
const getOrderLists = (data,page)=>({
    type:constans.GET_ORDERLISTS,
    data:data,
    page:page
})
const cancelOrders = ()=>({
    type:constans.CANCELORDERS,
    data:'cancelorder'
})
// 显示修改密码框
export const showChangePass=()=>({
    type:constans.SHOW_CHANGEPASS
})
// 修改密码
const changePassword = ()=>({
    type:constans.CHANGE_PASSWORD
})
// 显示修改手机号框
export const showChangePhone = ()=>({
    type:constans.SHOW_CHANGEPHONE
})
// 显示修改邮箱框
export const showChangeEmail = ()=>({
    type:constans.SHOW_CHANGEEMAIL
})
// 关闭所有弹窗
export const close = ()=>({
    type:constans.CLOSE
})
// 获取产品列表
export const getProductList = (page)=>{
    return(dispatch)=>{
        axios.get(url+'order/productList?page='+page+'&limit='+8).then((res)=>{
            if(res.data.code === 200){
                returnTop.returnTop();
                dispatch(List(res.data.data,res.data.page));
            }else{
               
            }
        }).catch((res)=>{
        })
    }
}
// 获取订单列表
export const getOrderList = (page)=>{
    return(dispatch)=>{
        axios.get(url+'order/list?page='+page+'&limit='+8).then((res)=>{
            if(res.data.code===200){
                returnTop.returnTop();
                dispatch(getOrderLists(res.data.data,res.data.page));
            }else{
               
            }
        }).catch((res)=>{
        })
    }
}
const getMessageLists = (data,page)=>({
    type:constans.GET_MESSAGELISTS,
    data:data,
    page:page
})
// 获取消息列表
export const getMessageList = (page)=>{
    return (dispatch)=>{
        axios.get(url+'message/getMessages?page='+page+'&limit='+8).then((res)=>{
            if(res.data.code === 200){
                returnTop.returnTop();
                dispatch(getMessageLists(res.data.data,res.data.page))
            }
        })
    }
}
// 取消订单
export const cancelOrder = (orderId)=>{
    return(dispatch)=>{
        axios.get(url+'order/cancelTimeOutOrderById?orderId='+orderId).then((res)=>{
            if(res.data.code === 200){
                message.success("取消成功");
                dispatch(cancelOrders())
            }else{
                
            }
        })
    }
}
export const sendChangePassLoadingTrue = ()=>({
    type:constans.SEND_CHANGEPASSLOADINGTRUE
})
const sendChangePassLoadingFalse = ()=>({
    type:constans.SEND_CHANGEPASSLOADINGFALSE
})
// 修改密码
export const sendChangePass = (values,phone)=>{
    return(dispatch)=>{
        axios.post(url+'UpdataPassword2?phoneNum='+phone+'&oldPassWord='+values.oldPassword+'&newPassWord='+values.password).then((res)=>{
            dispatch(sendChangePassLoadingFalse())
            if(res.data.code === 200){
                message.success("密码修改成功，下次登录时请用新密码登录");
                dispatch(changePassword());
            }else{
               
            }
        })
    }
}
export const sendFirstCodeLoadingTrue = ()=>({
    type:constans.SEND_FIRSTCODELOADINGTRUE
})
// 第一步发送验证
const sendFrist = ()=>({
    type:constans.SEND_FRIST
})
const sendFirstCodeLoadingFalse = ()=>({
    type:constans.SEND_FIRSTCODELOADINGFALSE
})
export const sendFirstCode = (values,phone)=>{
    return(dispatch)=>{
        axios.post(url+'CheckCode?key='+phone+'&code='+values.codeold).then((res)=>{
            dispatch(sendFirstCodeLoadingFalse())
            if(res.data.code === 200){
                dispatch(sendFrist());
            }else{
                
            }
        })
    }
}
export const sendSercondCodeLoadingTrue = ()=>({
    type:constans.SEND_SERCONDCODELOADINGTRUE
})
const sendSercondCodeLoadingFalse = ()=>({
    type:constans.SEND_SERCONDCODELOADINGFALSE
})
// 第二步发送验证
const sendSercond = ()=>({
    type:constans.SEND_SERCOND
})
export const sendSercondCode = (values,phone)=>{
    return(dispatch)=>{
        axios.post(url+'UpdataPhoneNumAndMail?key='+phone+'&code='+values.codenew).then((res)=>{
            dispatch(sendSercondCodeLoadingFalse()) 
            if(res.data.code===200){
                if(phone.length === 11){
                    window.localStorage.setItem('newPhone',phone);
                }else{
                    window.localStorage.setItem('newEmail',phone);
                }
                dispatch(sendSercond());
            }else{
                
            }
        })
    }
}
// 点击提交申请发票按钮
const submitApplications = ()=>({
    type:constans.SUBMITAPPLICATIONS
})
export const submitApplication = (values)=>{
    return(dispatch)=>{
        
        const data = {
            // orderId:values.orderId,
            bankName:values.bankName,
            bankNum:values.bankNum,
            companyAddress:values.companyAddress,
            invoiceContent:values.invoiceContent,
            invoiceMoney:values.invoiceMoney,
            invoiceTitle:values.invoiceTitle,
            receivingAddress:values.receivingAddress,
            receivingName:values.receivingName,
            receivingPhone:values.receivingPhone,
            taxpayersNum:values.taxpayersNum,
            titleType:values.titleType
        };
        axios.post(url+'order/createInvoice',data
        ).then((res)=>{
            console.log(res);
            if(res.data.code === 200){
                message.success("提交成功");
                dispatch(submitApplications())
               
            }else if(res.data.code === 500){
                dispatch(submitApplications())
            }
        }).catch((res)=>{
            console.log(res);
        })
    }
}
// 查看发票详情
const invoicings = (data)=>({
    type:constans.INVOICING,
    data:data
})
export const invoicing = (orderId)=>{
    return(dispatch)=>{
        axios.get(url+'order/getInvoice?orderId='+orderId).then((res)=>{
            console.log(res);
            if(res.data.code === 200){
                dispatch(invoicings(res.data.data));
            }
            
        })
    }
}