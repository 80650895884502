import * as constans from './constans';
import { fromJS } from 'immutable';
const defalueState = fromJS({
    apilist:''
});
export default (state = defalueState,action)=>{
    if(action.type === constans.GET_APILIST){
        return state.merge({
            'apilist':action.data,
        });
    }
    return state
}