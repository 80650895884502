import * as constans from './constans';
import { fromJS } from 'immutable';
const defalueState = fromJS({
    newxq:0
});
export default (state = defalueState,action)=>{
    if(action.type===constans.GETNEWSXQ){
        return state.set('newxq',action.data)
    }
    return state
}