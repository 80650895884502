import * as constans from './constans';
import { fromJS } from 'immutable';
const defalueState = fromJS({
    newIndex:0,
    newlist:[]
});
export default (state = defalueState,action)=>{
    if(action.type===constans.CHANGENEWS){
        return state.set('newIndex',action.data)
    }
    if(action.type === constans.NEWLIST){
        return state.set('newlist',action.data)

    }
    return state
}