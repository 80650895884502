export const LIST = 'personalCenter/LIST';
export const GET_ORDERLISTS = 'personalCenter/GET_ORDERLISTS';
export const CANCELORDERS = 'personalCenter/CANCELORDERS';
export const CHANGE_PASSWORD = 'personalCenter/CHANGE_PASSWORD';
export const SHOW_CHANGEPASS = 'personalCenter/SHOW_CHANGEPASS';
export const SHOW_CHANGEPHONE = 'personalCenter/SHOW_CHANGEPHONE';
export const SHOW_CHANGEEMAIL = 'personalCenter/SHOW_CHANGEEMAIL';
export const CLOSE = 'personalCenter/CLOSE';
export const CLOSEMODALS = 'personalCenter/CLOSEMODALS';
export const SEND_FRIST = 'personalCenter/SEND_FRIST';
export const SEND_SERCOND = 'personalCenter/SEND_SERCOND';
export const SEND_FIRSTCODELOADINGTRUE = 'personalCenter/SEND_FIRSTCODELOADINGTRUE';
export const SEND_FIRSTCODELOADINGFALSE = 'personalCenter/SEND_FIRSTCODELOADINGFALSE';
export const SEND_SERCONDCODELOADINGTRUE = 'personalCenter/SEND_SERCONDCODELOADINGTRUE';
export const SEND_SERCONDCODELOADINGFALSE = 'personalCenter/SEND_SERCONDCODELOADINGFALSE';
export const SEND_CHANGEPASSLOADINGTRUE = 'personalCenter/SEND_CHANGEPASSLOADINGTRUE';
export const SEND_CHANGEPASSLOADINGFALSE = 'personalCenter/SEND_CHANGEPASSLOADINGFALSE';
export const GET_MESSAGELISTS = 'personalCenter/GET_MESSAGELISTS';
export const INVOICING = 'personalCenter/INVOICING';
export const SUBMITAPPLICATIONS = 'personalCenter/SUBMITAPPLICATIONS';