import * as constans from './constans'
import axios from 'axios';
// import commenUrl from '../../../all';
// import { fromJS} from 'immutable';
import {url} from '../../../axiosUrl';
const registere = (values,email)=>({
    type:constans.REGISTERED,
    data:values,
    email:email
})
const failure = (values)=>({
    type:constans.FAILURE,
    data:values
})
export const regisLoadingTrue = ()=>({
    type:constans.REGISLOADINGTRUE
})
const regisLoadingFalse = ()=>({
    type:constans.REGISLOADINGFALSE
})
export const registered = (values)=>{
    return (dispatch)=>{
        axios.post(url+'register?userEmail='+
        values.userEmail+
        '&userPhone='+
        values.userPhone+
        '&userPassword='
        +values.password
        +'&phoneCode='+
        values.phoneCode
        +'&picCode='+
        values.picCode)
        .then(res=>{
            dispatch(regisLoadingFalse())
            if(res.data.code === 200){
                dispatch(registere(res.data,values.userEmail));  
            }else{
                dispatch(failure(res.data));
            }      
        }).catch(res=>{
            
        })
    }
    
}