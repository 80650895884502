import * as constans from './constans'
import axios from 'axios';
import { fromJS} from 'immutable';
import {url} from '../../../../axiosUrl'
const List = (data)=>({
    type:constans.GET_LIST,
    data:fromJS(data)
})
export const changeList = (num)=>({
    type:constans.CHANGELIST,
    data:num
}) 
export const changeTime = (num)=>({
    type:constans.CHANGETIME,
    data:num
})
export const getList = ()=>{
    return(dispatch)=>{
        axios.get(url+'/api/apiList.json').then((res)=>{
            const data = res.data;
			console.log(res.data);
            if(res.data.code === 200){
                dispatch(List(data));
            } 
        }).catch(()=>{
        })
    }
}
const getApiLists = (data)=>({
    type:constans.GET_APILIST,
    data:data
})
export const getApiList = ()=>{
    return(dispatch)=>{
        axios.get(url+'/getApiList').then((res)=>{
            if(res.data.code === 200){
				console.log(res.data.data);
                dispatch(getApiLists(res.data.data))
            }
        })
    }
}