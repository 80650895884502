import * as constans from './constans'
import axios from 'axios';
import { fromJS} from 'immutable';
import {url} from '../../../../axiosUrl';
import returnTop from '../../../../returnTop';
export const changeList = (num)=>({
    type:constans.CHANGELIST,
    data:num
}) 
export const changeTime = (num)=>({
    type:constans.CHANGETIME,
    data:num
})
const List = (data,totalRow)=>({
    type:constans.GET_LIST,
    data:fromJS(data),
    totalRow
})
export const getList = (page)=>{
    return(dispatch)=>{
        axios.get(url+'getPackageList?page='+page+'&limit=6').then((res)=>{
            if(res.data.code === 200){
                returnTop.returnTop();
                dispatch(List(res.data.data,res.data.page.totalRow));
            }
        }).catch((res)=>{
            console.log(res);
        })
    }
}
const handleOks = ()=>({
    type:constans.HANDLEOK,
    data:true
})
const abnormal = (message)=>({
    type:constans.ABNORMAL,
    data:message
})
export const submitLoadingTrue = ()=>({
    type:constans.SUBMIT_LOADINGTRUE
})
const submitLoadingFalse = ()=>({
    type:constans.SUBMIT_LOADINGFALSE
})
export const submitOrder = (packageCode,duration,orderMoney,history) =>{
    return (dispatch)=>{
        const data = {
            packageCode:packageCode,
            duration:duration,
            orderMoney:orderMoney
        };
        axios.post(url+'order/confirmOrder',data
        ).then((res)=>{
            console.log(res);
            if(res.data.code === 200){

                // 提交成功订单需要跳转到订单详情页
                history.push('/determine/'+res.data.data.orderId);
            }else{
                // 订单异常
                if(res.data.code===101){
                    dispatch(abnormal(res.data.message))
                }else{
                }
            }
            dispatch(submitLoadingFalse())
        }).catch((res)=>{
            console.log(res);
        })
    }
}
const handleCancels = ()=>({
    type:constans.HANDLE_CANCEL
})
export const handleOk = (packageCode,duration,orderMoney,history,y)=>{
    return (dispatch)=>{
        const data = {
            packageCode:packageCode,
            duration:duration,
            orderMoney:orderMoney,
            isPurchase:y
        }
        axios.post(url+'order/confirmOrder',data
        ).then((res)=>{
            console.log(res);
            console.log(history);
            dispatch(handleCancels());
            if(res.data.code === 200){
                // 提交成功订单需要跳转到订单详情页
                history.push('/determine/'+res.data.data.orderId);
            }else{
                // 订单异常
                if(res.data.code===101){
                    dispatch(handleOks(res.data.message))
                }else{
                }
            }
        }).catch((res)=>{
        })
    }
}
export const handleCancel = ()=>({
    type:constans.HANDLE_CANCEL
})