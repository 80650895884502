import * as constans from './constans'
import axios from 'axios';
import {url} from '../../../axiosUrl';
// import { fromJS} from 'immutable';
const statusError = (data)=>({
    type:constans.STATUS_ERROR,
    message:data
})
const statusSuccess = ()=>({
    type:constans.STATUS_SUCCESS
})
export const status = (code)=>{
    return(dispatch)=>{
        axios.get(url+'check?checkcode='+code).then((res)=>{
            if(res.data.code === 404){
                dispatch(statusError(res.data.message))
            }else{
                dispatch(statusSuccess())
            }
        })
    }
}