import * as constans from './constans';
import { fromJS } from 'immutable';
const defalueState = fromJS({
    numberServies:1,
    autoplay:true
});
export default (state = defalueState,action)=>{
    if(action.type===constans.AUTO_PLAY_FALSE){
        state.set('autoplay',false);
    }
    if(action.type === constans.AUTO_PLAY_TRUE ){
        state.set('autoplay',true);
    }
    return state
}