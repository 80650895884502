import * as constans from './constans';
import { fromJS } from 'immutable';
const defalueState = fromJS({
    product:[],
    orderList:[],
    messageList:[],
    iscancelOrder:false,
    totalPage:'',
    productPages:'',
    messagePages:'',
    isShowchangepass:false,
    isShowchangeohone:false,
    isShowchangeemail:false,
    twoSuccess:false,
    threeSuccess:false,
    FirstBtnLoading:false,
    SercondBtnLoading:false,
    changePassLoading:false,
    invoiceDetails:'',
    invoiceSuccess:false
});
export default (state = defalueState,action)=>{
    // 获取所有套餐
    if(action.type===constans.LIST){
        return state.merge({
            'product':action.data,
            'productPages':action.page.totalRow
        });
    }
    if(action.type === constans.GET_ORDERLISTS){
        return state.merge({
            'orderList':action.data,
            'iscancelOrder':false,
            'totalPage':action.page.totalRow
        })
    }
    if(action.type === constans.GET_MESSAGELISTS){
        return state.merge({
            'messageList':action.data,
            'messagePages':action.page.totalRow
        })
    }
    if(action.type===constans.CANCELORDERS){
        return state.set('iscancelOrder',true)
    }
    // 显示修改密码框
    if(action.type===constans.SHOW_CHANGEPASS){
        return state.set('isShowchangepass',true)
    }
    // 修改密码
    if(action.type===constans.CHANGE_PASSWORD){
        return state.set('isShowchangepass',false)
    }
    // 显示修改手机号框
    if(action.type === constans.SHOW_CHANGEPHONE){
        return state.set('isShowchangeohone',true)
    }
    // 显示修改邮箱框
    if(action.type === constans.SHOW_CHANGEEMAIL){
        return state.set('isShowchangeemail',true)
    }
    // 关闭所有弹窗
    if(action.type===constans.CLOSE){
        return state.merge({
            'isShowchangepass':false,
            'isShowchangeohone':false,
            'isShowchangeemail':false,
            'twoSuccess':false,
            'threeSuccess':false
        })
    }
    // 修改手机号码第一步
    if(action.type===constans.SEND_FRIST){
        return state.merge({
            'twoSuccess':true
        })
    }
    // 修改手机号码第二步
    if(action.type === constans.SEND_SERCOND){
        
        return state.merge({
            'threeSuccess':true
        })
    }
    if(action.type === constans.SEND_FIRSTCODELOADINGTRUE){
        return state.merge({
            'FirstBtnLoading':true
        })
    }
    if(action.type === constans.SEND_FIRSTCODELOADINGFALSE){
        return state.merge({
            'FirstBtnLoading':false
        })
    }
    if(action.type === constans.SEND_SERCONDCODELOADINGTRUE){
        return state.merge({
            'SercondBtnLoading':true
        })
    }
    if(action.type === constans.SEND_SERCONDCODELOADINGFALSE){
        return state.merge({
            'SercondBtnLoading':false
        })
    }
    if(action.type === constans.SEND_CHANGEPASSLOADINGTRUE){
        return state.merge({
            'changePassLoading':true
        })
    }
    if(action.type === constans.SEND_CHANGEPASSLOADINGFALSE){
        return state.merge({
            'changePassLoading':false
        })
    }
    // 发票明细
    if(action.type === constans.INVOICING){
        return state.merge({
            'invoiceDetails':action.data
        })
    }
    // 发票提交成功
    if(action.type === constans.SUBMITAPPLICATIONS){
        return state.merge({
            'invoiceSuccess':true
        })
    }
    return state
}