import * as constans from './constans';
import { fromJS } from 'immutable';
const defalueState = fromJS({
    city:[],
    UserName:'',
    UserSex:'',
    AddressCity:'',
    AddressProvince:'',
    Company:'',
    Position:'',
    isChangesuccess:false,
    modifyDataLoading:false
});
export default (state = defalueState,action)=>{
    // 获取所有套餐
    if(action.type===constans.CITY){
        return state.set('city',action.data);
    }
    if(action.type===constans.USERINFO){
        return state.merge({
            'UserName':action.data.UserName,
            'UserSex':action.data.UserSex,
            'AddressCity':action.data.AddressCity,
            'AddressProvince':action.data.AddressProvince,
            'Company':action.data.Company,
            'Position':action.data.Position,
        })
    }
    if(action.type === constans.CHANGEINFO){
        return state.set('isChangesuccess',true)
    }
    if(action.type === constans.MODIFY_DATALOADINGTRUE){
        return state.set('modifyDataLoading',true)
    }
    if(action.type === constans.MODIFY_DATALOADINGFALSE){
        return state.set('modifyDataLoading',false)
    }
    return state
}