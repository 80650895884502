import * as constans from './constans'
import axios from 'axios';
import {url} from '../../../../axiosUrl';
import { message } from 'antd';
export const changeNews = (num)=>({
    type:constans.CHANGENEWS,
    data:num
})
const newList = (data)=>({
    type:constans.NEWLIST,
    data
})
export const getNewsList = ()=>{
    return (dispatch)=>{
        axios.get(url+'queryWeather/newsBytype?type='+2).then((res)=>{
            if(res.data.code === 200){
                dispatch(newList(res.data.data));
            }else{
                message.error(res.data.message);
            }
        })
    }
}