import { combineReducers } from 'redux-immutable';
import {reducer as headerReduce} from '../common/header/store';
import {reducer as reducerHome} from '../pages/home/components/store';
import {reducer as reducerBuy} from '../pages/buy/components/store';
import {reducer as reducerNews} from '../pages/news/components/store';
import {reducer as reducerNewsDetail} from '../pages/newsDetail/components/store';
import {reducer as reducerLogin} from '../pages/login/store';
import {reducer as reducerRegistered} from '../pages/registered/store';
import {reducer as reducerDetermine} from '../pages/determine/components/store';
import {reducer as reducerPersonal} from '../pages/personalCenter/components/store';
import { reducer as reducerReset } from '../pages/resetPassword/store';
import {reducer as reducerModifyData} from '../pages/modifyData/components/store'
import {reducer as reducerEmail} from '../pages/emailActivationStatus/store';
import {reducer as reducerdev} from '../pages/developmentdocument/components/store';
import {reducer as reducerweatherApi} from '../pages/weatherApi/components/store'
const reduce = combineReducers({
    header:headerReduce,
    home:reducerHome,
    login:reducerLogin,
    buy:reducerBuy,
    news:reducerNews,
    newsxq:reducerNewsDetail,
    registered:reducerRegistered,
    determine:reducerDetermine,
    personal:reducerPersonal,
    reset:reducerReset,
    modifyData:reducerModifyData,
    email:reducerEmail,
    dev:reducerdev,
    weatherApi:reducerweatherApi
})
export default reduce
