import * as constans from './constans';
import { fromJS } from 'immutable';
const defalueState = fromJS({
    reset:false,
    resetLoading:false
});
export default (state = defalueState,action)=>{
    switch(action.type){
        case constans.RESET :
            return state.merge({
                'reset':true
            });
        case constans.RESET_LOADINGTRUE:
            return state.merge({
                'resetLoading':true
            })
        case constans.RESET_LOADINGFALSE:
            return state.merge({
                'resetLoading':false
            })
        default:
            return state;
    }
}