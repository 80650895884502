import * as constans from './constans';
import { fromJS } from 'immutable';
const defalueState = fromJS({
    PackageList:[],
    money:0,
    total:0,
    timeList:[1,2,3,4,5,6,7,8,9,10,11,12],
    time:1,
    submit:false,
    visible:false,
    ModalText:'',
    confirmLoading:false,
    totalpage:0,
    submitLoading:false,
	serviceTimes:0
});
export default (state = defalueState,action)=>{
    // 获取所有套餐
    if(action.type===constans.GET_LIST){
        return state.merge({
            'PackageList':action.data,
            'totalpage':action.totalRow
        });
    }
    // 选择套餐
    if(action.type===constans.CHANGELIST){
        return state.merge({
            money:action.data,
            total:action.data
        })
    }
    // 选择月份
    if(action.type===constans.CHANGETIME){
        return state.set('time',action.data)
    }
    // 提交成功
    if(action.type===constans.SUBMIT){
        return state.merge({
            submit:true
        })
    }
    if(action.type === constans.ABNORMAL){
        return state.merge({
            visible:true,
            ModalText:action.data
        })
    } 
    //点击取消
    if(action.type === constans.HANDLE_CANCEL){
        return state.merge({
            visible:false
        })
    } 
    if(action.type === constans.SUBMIT_LOADINGTRUE){
        return state.merge({
            submitLoading:true
        })
    } 
    if(action.type===constans.SUBMIT_LOADINGFALSE){
        return state.merge({
            submitLoading:false
        })
    }
    return state
}