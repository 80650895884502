import * as constans from './constans';
import { fromJS } from 'immutable';
const defalueState = fromJS({
    order:[],
    AlipayLoading:false,
    WXpayLoading:false,
    wxpaycode:[],
    wxpaycodeShow:false,
    code:''
});
export default (state = defalueState,action)=>{
    if(action.type===constans.ORDER){
        return state.merge({
            'order':action.data
        })
    }
    if(action.type === constans.WXPAY_LOADING){
        return state.merge({
            'WXpayLoading':false
        })
    }
    if(action.type === constans.WXPAY_LOADINGTRUE){
        return state.merge({
            'WXpayLoading':true
        })
    }
    if(action.type === constans.WXPAYCODE){
        return state.merge({
            'wxpaycode':action.data,
            'wxpaycodeShow':true
        })
    }
    if(action.type === constans.CLOSEMODAL){
        return state.merge({
            'wxpaycodeShow':false
        })
    }
    if(action.type === constans.ALIPAY_LOADINGTRUE){
        return state.merge({
            'AlipayLoading':true
        })
    }
    if(action.type === constans.ALIPAY_LOADINGFALSE){
        return state.merge({
            'AlipayLoading':false
        })
    }
    if(action.type === constans.OTHERPAY){
        return state.merge({
            'code':action.data
        })
    }
    return state
}