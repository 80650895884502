import * as constans from './constans';
import { fromJS } from 'immutable';
const defalueState = fromJS({
    registered:false,
    regisloading:false,
    email:''
});
export default (state = defalueState,action)=>{
    switch(action.type){
        case constans.REGISTERED :
            return state.merge({
                'registered':true,
                'email':action.email
            });
        case constans.FAILURE:
            return state.merge({
                'registered':false
            });
        case constans.REGISLOADINGTRUE:
            return state.merge({
                'regisloading':true
            })
        case constans.REGISLOADINGFALSE:
            return state.merge({
                'regisloading':false
            })
        default:
            return state;
    }
}