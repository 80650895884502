import * as constans from './constans'
import axios from 'axios';
import {url} from '../../../axiosUrl';
const changeLogin = (result)=>({
    type:constans.CHANGE_LOGIN,
    value:true,
    data:result
})
const gropOuts =()=>({
    type:constans.GROPOUT,
    value:false
})
// 开始loading
export const startloading = ()=>({
    type:constans.START_LOADING
})
// 停止loading
const stoploading = ()=>({ 
    type:constans.STOP_LOADING
})
//退出登录
export const gropOut = ()=>{
    return (dispatch)=>{
        axios.get(url+'logout').then((res)=>{
            if(res.data.code === 200){
                dispatch(gropOuts())
            }else{
                
            }
        })
    }
}
export const loginTrue = ()=>({
    type:constans.LOGIN_TRUE
})
const loginfalse = ()=>({
    type:constans.LOGIN_FALSE
})
export const loginPhone = (accout,passw)=>{
    return (dispatch)=>{
        axios.post(url+'login?userPhone='+accout+'&userPassword='+passw).then((res)=>{
            dispatch(loginfalse())
            if(res.data.code === 200){
                dispatch(changeLogin(res));//请求成功
            }else{
            }
        })
    }
}
export const loginEmail = (accout,passw)=>{
    return (dispatch)=>{
        axios.post(url+'login?userEmail='+accout+'&userPassword='+passw).then((res)=>{
            dispatch(loginfalse())
            if(res.data.code === 200){
                dispatch(changeLogin(res))
            }else{
            }
        })
    }
}