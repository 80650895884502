import { createGlobalStyle } from 'styled-components';
import styled from 'styled-components';
export const size16 = "16px";
export const size14 = "14px";
export const size24 = "24px";
export const size20 = "20px";
export const ten = "10px";
export const twenty = "20px";
export const thirty = "30px";
export const forty = "40px";
export const fifty = "50px";
export const GlobalStyle = createGlobalStyle`
div{
    font-size: 40px;
}
.model .ant-form-explain{
    margin-left:10px;
}
@media only screen and (max-width: 1200px) { div{ font-size: 39px; }}
@media only screen and (max-width: 1100px) { div{ font-size: 38px; }}
@media only screen and (max-width: 1000px) { div{ font-size: 37px; }}
@media only screen and (max-width: 900px) { div{ font-size: 36px; }}
@media only screen and (max-width: 800px) { div{ font-size: 35px; }}
@media only screen and (max-width: 700px) { div{ font-size: 34px; }}
@media only screen and (max-width: 600px) { div{ font-size: 33px; }}
@media only screen and (max-width: 500px) { div{ font-size: 32px; }}
@media only screen and (max-width: 400px) { div{ font-size: 31px; }}
@media only screen and (max-width: 300px) { div{ font-size: 30px; }}
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center, dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td {
margin: 0;
padding: 0;
border: 0;
outline: 0;
font-size: 100%;
vertical-align: baseline;
background: transparent;
}
.Spins{
    position:fixed;
    width:100%;
    height:100%;
    left:0;top:0;
}
a{
   text-decoration:none;
   display:block;
}
a:hover{
    color:#426fe2 !important;
}
a:hover div{
    color:#426fe2 !important;
}
a:hover span{
    color:#426fe2 !important;
}
a:hover li{
    color:#426fe2 !important;
}
a.login:hover{
    color:#fff !important;
}
a.on{
    color:#426fe2 !important; 
}
a.on:hover{
    color:#fff !important;
}
a.on div{
    color:#426fe2 !important;
}
a.on span{
    color:#426fe2 !important;
}
a.on li{
    color:#426fe2 !important;
}
a.on div:hover{
    color:#426fe2 !important;
}
a.on span:hover{
    color:#426fe2 !important;
}
a.on li:hover{
    color:#426fe2 !important;
}
.ant-back-top {
    height:44px;
    background: #fff;
    text-align: center;
    border: 1px solid #ddd;
}
body {
line-height: 1;
}
ol, ul {
list-style: none;
}
blockquote, q {
quotes: none;
}
blockquote:before,blockquote:after,
q:before, q:after {
content: '';
content: none;
}
/* remember to define focus styles! */
:focus {
outline: 0;}
/* remember to highlight inserts somehow! */
ins {
text-decoration: none;
}
del {
text-decoration: line-through;
}
/* tables still need 'cellspacing="0"' in the markup */
table {
border-collapse: collapse;
border-spacing: 0;
}
body{
    background:#fff !important;
}
.ant-pagination{
    text-align:center;
}
`
export const Spins = styled.div`
    width:100%;
    position:fixed;
    left:0;
    top:0;
    text-align:center;
    line-height:100%;
    padding:20% 0;
    z-index:1;
`
export default GlobalStyle
