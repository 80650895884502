import * as constans from './constans';
import { fromJS } from 'immutable';
import { createHashHistory } from 'history'
const defalueState = fromJS({
    user:null,
    loginLoading:false
});
export default (state = defalueState,action)=>{
    switch(action.type){
        case constans.CHANGE_LOGIN :
            window.localStorage.setItem('user', JSON.stringify(action.data.data.data));
            const loginUser = JSON.parse(window.localStorage.getItem('user'));
            return state.merge({
                'user':loginUser,
            });
        case constans.GROPOUT :
            localStorage.clear();
            const logoutUser = JSON.parse(window.localStorage.getItem('user'));
            createHashHistory().push('/login?url=null');
            return state.merge({
                'user':logoutUser,
            });
        case constans.LOGIN_TRUE:
            return state.merge({
                'loginLoading':true
            })
        case constans.LOGIN_FALSE:
            return state.merge({
                'loginLoading':false
            })
        default:
            const userDefalue = JSON.parse(window.localStorage.getItem('user'));
            return state.merge({
                'user':userDefalue
            });
    }
}