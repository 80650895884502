import * as constans from './constans'
import axios from 'axios';
import { fromJS} from 'immutable';
import {url} from '../../../axiosUrl';
const getListcity = (data)=>({
    type:constans.GET_LIST,
    data:fromJS(data)
})
export const getList = ()=>{
    return(dispatch)=>{
        axios.get('/api/station.json').then((res)=>{
            const data = res.data;
            dispatch(getListcity(data));
        }).catch(()=>{
            console.log("error");
        })
    }
}
const weatherCurrent = (data,city)=>({
    type:constans.WEATHER,
    data,
    city
})
export const cityId = (id,city)=>{
    return(dispatch)=>{
        axios.get(url+'queryWeather/CurrentByStationid?stationid='+id).then((res)=>{
            if(res.data.code === 200){
                dispatch(weatherCurrent(res.data.data,city))
            }
        })
    }
}