import * as constans from './constans';
import { fromJS } from 'immutable';
const defalueState = fromJS({
    status:null,
    message:''
});
export default (state = defalueState,action)=>{
    if(action.type === constans.STATUS_ERROR){
        return state.merge({
            'status':false,
            'message':action.message
        })
    }
    if(action.type === constans.STATUS_SUCCESS){
        return state.merge({
            'status':true
        })
    }
    return state
}