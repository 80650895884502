const returnTop=function(){
    var timer = null; //定义一个定时器
    var isTop = true;
    timer = setInterval(function(){
        //获取滚动条的滚动高度
        var osTop = document.documentElement.scrollTop || document.body.scrollTop;
        //用于设置速度差，产生缓动的效果
        var speed = Math.floor(-osTop / 6);
        document.documentElement.scrollTop = document.body.scrollTop = osTop + speed;
        isTop = true;  //用于阻止滚动事件清除定时器
        if(osTop === 0){
            clearInterval(timer);
        }
    },20);
}
export default {returnTop}
