import * as constans from './constans'
import axios from 'axios';
import { url } from '../../../../axiosUrl';
const getApiR = (data,type) => ({
    type: constans.GETAPIR,
    data: data,
	types:type
})
const getApiC = (data,type) => ({
    type: constans.GETAPIC,
    data: data,
	types:type
})
const getApiC2 = (data) => ({
    type: constans.GETAPIC2,
    data: data
})
export const getApi = (type, V, c) => {
    return (dispatch) => {
        if (c === "2") {
            dispatch(getApiC2(type));
        } else {
            axios.get(url + 'apiTemplate/getData?type=' + type + '&v=' + V + '&c=' + c).then((res) => {
                if (c === "0") {
                    if (res.data != null && res.data != undefined && res.data != "") {
                        dispatch(getApiR(res.data,type))
                    }
                } else if (c === "1") {
                    if (res.data != null && res.data != undefined && res.data != "") {
                        dispatch(getApiC(res.data,type))
                    }
                }

            }).catch((res) => {
                console.log(res);
            })
        }

    }
}
const getApiNavR = (data) => ({
    type: constans.GETAPINAVR,
    data: data
})
export const getApiNav = () => {
    return (dispatch) => {
        axios.get(url + 'apiTemplate/getCategoryData?v=V1').then((res) => {
            if (res.data != null && res.data != undefined && res.data != "") {
                dispatch(getApiNavR(res.data))
            }
        }).catch((res) => {
            console.log(res);
        })
    }
}