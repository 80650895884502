import * as constans from './constans';
import { fromJS } from 'immutable';

const defalueState = fromJS({
    changeValue:['101010100','郑州'],
    defaultValue:['henan','101010100','郑州'],
    chooseCity :[],
    changeCitys:'北京',
    weather:[],
    cityid:101010100
});
export default (state = defalueState,action)=>{
    switch(action.type){
        case constans.GET_LIST:
        return state.set('chooseCity',action.data);
        case constans.WEATHER:
            return state.merge({
                'changeCitys':action.city,
                'weather':action.data
            })
        default:
        return state
    }
}